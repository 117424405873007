/* Main */

.searchform {
    margin-bottom: 25px;
}

.searchform>.input-group>.input-group-addon {
    color: var(--color-primary);
    background-color: transparent;
}

.suggestion {
    border-bottom: 2px solid var(--color-secundary);
    color: var(--color-secundary);
}

.suggestion input.form-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: unset;
}

.suggestion .input-group-addon {
    background-color: transparent;
}

.suggestion .svg-icon {
    height: 18px;
    width: 18px;
}

.sidemenu_ug_row {
    min-height: 30px;
    margin-left: -10px;
    margin-right: -1px;
    padding-top: 5px;
    padding-left: 20px;
    list-style: none;
    text-align: left;
}

.startportal_title {
    font-size: 38px;
    color: var(--color-secundary);
    /*margin-left: 15px;*/
    font-weight: 500;
    /* text-align: center; */
    /* text-transform: uppercase; */
}

/*
.sidemenu_ug_row>a:hover {
    color: var(--color-primary);
    background: #fff;
}
*/

/*li.sidemenu_ug_row:hover {
    color: var(--color-primary);
    background: #fff;
}*/

/*
.sidemenu_ug_row.active>a {
    color: var(--color-primary);
    background: #fff;
}
*/

#content .breadcrumb {
    padding: 0;
    background-color: #fff;
}

.autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete ul {
    position: absolute;
    left: 0;
    top: 34px;
    z-index: 1;
    width: 100%;
    padding-left: 0;
    border-left: 1px solid #888;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
}

.autocomplete li {
    width: 100%;
    padding: .4em 1em;
    text-align: left;
    list-style: none;
    background-color: #fff;
}

.autocomplete li.active {
    width: 100%;
    background-color: #4bf;
}

.autocomplete .highlight {
    background-color: #e2e2e2;
}

.autocomplete li.active .highlight {
    color: #fff;
    background: #666;
}

ul.dropdown-menu ul {
    list-style: none;
    line-height: 2;
}

.wk-anzahl {
    position: absolute;
    top: 0;
    left: 15px;
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--color-secundary);
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.xxx--page-header--menu .svg-icon__menu {
    height: 1rem;
    width: 1rem;
    display: inline-flex;
}

/*
*
* Medium (MD)
*/

@media (max-width: 991px) {
    /*.suggestion {
        border-bottom: 2px solid #fff;
        color: #fff;
    }*/
    .navbar__top {
        padding: 0 !important;
    }
}

