/* Breadcrumb */

.breadcrumb>li,
.breadcrumb>li>a {
    color: #666;
    font-size: 1.16rem;
}

.breadcrumb .breadcrumb__titel {
    font-size: 1.16rem;
    font-weight: 500;
    margin: 0;
    display: contents;
    color: var(--color-primary);
}

.breadcrumb .breadcrumb__item {
    display: none !important;
}

.breadcrumb .breadcrumb__item.breadcrumb__erster {
    display: unset !important;
}

.breadcrumb .breadcrumb__item:nth-last-child(-n+2) {
    display: unset !important;
}

.breadcrumb .breadcrumb__li .breadcrumb__items {
    display: none !important;
}

.breadcrumb .breadcrumb__li:nth-last-child(-n+2) .breadcrumb__punkte {
    display: none !important;
}

.breadcrumb .breadcrumb__li:nth-last-child(-n+2) .breadcrumb__items {
    display: unset !important;
}
