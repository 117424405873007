/* Allgemein */

html {
    font-family: var(--font-primary);
    font-size: 15px;
}

body {
    font-family: var(--font-primary);
    color: var(--font-color);
    font-size: 1rem;
}

html {
    /* For Firefox */
    overflow-y: scroll;
    scrollbar-color: var(--scrollbar-thumbs-color) var(--scrollbar-track-color);
    scrollbar-width: auto;
}


body::-webkit-scrollbar {
    width: 18px;
}

body::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumbs-color);
    border-radius: 20px;
    border: 2px solid var(--scrollbar-track-color);
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumbs-color) var(--scrollbar-track-color);
}

a,
a *,
button,
button *,
.btn,
.btn *,
.cursor-pointer,
.cursor-pointer *,
*[href],
*[href] *,
*[onclick],
*[onclick] *,
*[ondblclick],
*[ondblclick] *,
*[ui-sref],
*[ui-sref] *,
*[ng-click],
*[ng-click] * {
    cursor: pointer !important;
}

img {
    max-width: 100%;
    height: auto;
}

svg {
    fill: currentColor;
}

svg *[fill]{
    fill: currentColor;
}

svg *[stroke]{
    stroke: currentColor;
}

.btn.xxx---is-active,
.btn.xxx---is-active * {
    cursor: default !important;
}

.table>tbody>tr>td {
    border: none;
}

.table.table-bordered>tbody>tr>td {
    border: 1px solid #ddd;
}

a,
p {
    color: var(--font-color);
    font-size: 1rem;
}

p {
    margin: 0;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
    font-family: var(--font-primary);
    color: var(--font-color);
    font-size: 1rem;
    text-decoration: none;
}

.link__color {
    color: var(--link-color) !important;
}

.link__color:hover {
    /*text-decoration: underline;*/
}

h1,
h2,
h3,
h4,
h5 {
    color: var(--color-secundary);
    font-weight: bold;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.3rem;
    /*text-transform: uppercase;*/
    font-weight: 400;
}

h3 {
    font-size: 1.42rem;
}

h4 {
    font-size: 1.3rem;
}

h5 {
    font-size: 1.16rem;
}

h5.italic {
    font-weight: 500;
}

b,
strong {
    font-weight: 600;
}

label {
    font-size: 1rem;
}

input.form-control,
select.form-control {
    height: 3.74rem;
    font-size: 1rem;
}

/*
 *
 * Buttons
 */

.btn {
    padding: 14px 20px;
    border: 2px solid;
    border-radius: 72px;
    font-size: unset;
}

.gea__select.form-control,
.gea__input.form-control {
    background-color: #fff;
    height: unset;
    border: 2px solid var(--color-secundary);
    padding: 14px 20px;
    border-radius: 72px !important;
}

.btn-default {
    background: #fff;
    border-color:var(--color-secundary);
    color: var(--color-secundary) !important;
    transition: background 800ms ease;
}

.btn-default:focus,
.btn-default:hover {
    background: var(--color-secundary);
    border-color: var(--color-secundary);
    color: #fff !important;
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    background: var(--color-secundary);
    border-color: var(--color-secundary);
}

.btn-primary {
    background: var(--color-button);
    border-color: var(--color-button);
    color: var(--color-primary) !important;
    transition: background 800ms ease;
}

.btn-primary:focus,
.btn-primary:hover {
    background: #fff;
    border-color: var(--color-button);
    color: var(--color-secundary) !important;
}

.btn-primary-reverse {
    background: #fff;
    border-color: var(--color-button);
    color: var(--color-primary) !important;
    transition: background 800ms ease;
}

.btn-primary-reverse:focus,
.btn-primary-reverse:hover {
    background: var(--color-button);
    border-color: var(--color-button);
    color:var(--color-primary) !important;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: var(--color-button);
    border-color: var(--color-button);
}

.pagination>li {
    display: inline-flex;
    margin-left: 5px;
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: var(--color-primary);
    text-decoration: none;
    background-color: #fff;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.pagination>.active>a {
    background-color: var(--color-secundary);
    color: #fff;
    border-color: var(--color-secundary);
}

.pagination>.active>a:hover {
    background-color: var(--color-primary);
    color: #fff;
    border-color: var(--color-secundary);
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    z-index: 2;
    color: #fff;
    background-color: var(--color-primary);
    border-color: var(--color-secundary);
}

dt {
    font-weight: 600;
}

.subtitel {
    font-size: 1.42rem;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
}


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    html {
        font-size: 12px;
    }
}


