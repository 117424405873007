/* RESULT */

.result__item {
    background-color: var(--color-grau);
}

.wrapper {
    display: flex;
    border: thin solid var(--color-grau);
    transition: box-shadow 500ms ease;
}

.wrapper:hover {
    box-shadow: var(--box-shadow);
}

.liste .wrapper {
    /*min-height: 230px;*/
}

.result {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.result__info {
    padding: 15px 22px;
}

.row__preis,
.row__bestand {
    display: flex;
}

.row__bestand .result__bestand {
    min-height: 1.5rem;
}

.row__bestand .result__kostenstelle {
    min-height: 2.5rem;
}

.result__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0; */
}

.result__action a {
    color: var(--color-secundary);
}

.result__kostenstelle .svg-icon {
    height: 20px;
    width: 20px;
}

.liste .result {
    flex-direction: column;
    row-gap: 1rem;
}

.liste .result__info {
    flex-grow: 1;
}

.result__info .input-group {
    width: 100%;
}

.liste .list__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 10%;
    max-width: 10%;
}

.liste .result__image {
    flex: 0 0 25%;
    max-width: 25%;
}

.card .wrapper {
    flex-direction: column;
}

/*.card .result__link {
    aspect-ratio: 4/3;
}*/

.result__label .result__titel {
    margin-top: 10px;
    font-weight: 700;
}

.card .result__label .result__titel {
    min-height: 4rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /*word-break: break-all;*/
    word-break: break-word;
    color: #000;
    font-size: 1.2rem;
}

.card .result__info .row__preis,
.card .result__info .row__bestand {
    flex-direction: column;
}

.liste .result__action {
    flex-direction: column;
}

.result__zusatz {
    min-height: 1.3rem;
}

.wrapper .svg-icon.svg-icon__mz {
    height: 1.2rem;
    width: 1.2rem;
    top: 15px;
    right: 15px;
    /*display: none;*/
}

.result__item .result__action {
    position: absolute ;
    top: 10px;
    right: 0;
}

.result__image {
    overflow: hidden;
}

.result__item .bg__image,
.detail__img img {
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
}

.result__item:hover .bg__image {
    transform: scale(1.2);
    transition: transform .6s ease-in-out;
}

/*.result__btn,
.detail__btn {
    height: 3.4rem;
    width: 3.4rem;
    display: inline-flex;
}*/

.result__btn .svg-icon,
.detail__btn .svg-icon {
    height: 2rem;
    width: 2rem;
}

.result__btn .svg-icon,
.detail__btn .svg-icon,
.result__btn i,
.detail__btn i,
.result__btn span,
.detail__btn span {
    margin: auto;
}

.preis__aktion,
.preis-ohne__aktion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 20px;
}

.preis-ohne__aktion {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    min-height: 20px;
}

.preis__aktion p,
.preis__block span {
    font-size: 1.3rem;
    line-height: 1.3rem;
    /*font-weight: 700;*/
    color: var(--color-primary);
    margin-bottom: 5px;
}

.list__item {
    display: flex;
}

.item__term {
    margin-right: 1rem;
    font-weight: 400;
}

.result__langtext {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    max-height: 20px;
    max-width: 500px;
}

.begrenz {
    min-height: 1.2rem;
}

.card .anzeige-none {
    display: none !important;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .liste .result__image {
        max-width: 100%;
    }
    .liste .wrapper {
        flex-direction: column;
    }
    .liste .result__label .result__titel {
        min-height: 6rem;
        word-break: break-all;
        /*word-break: break-word;*/
    }
    .liste .result__info .row__preis,
    .liste .result__info .row__bestand {
        flex-direction: column;
    }
    .liste .result__action {
        flex-direction: row;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .liste .result__image {
        max-width: 100%;
    }
    .liste .wrapper {
        flex-direction: column;
    }
    .liste .result__label .result__titel {
        min-height: 6rem;
        word-break: break-all;
        /*word-break: break-word;*/
    }
    .liste .result__info .row__preis,
    .liste .result__info .row__bestand {
        flex-direction: column;
    }
    .liste .result__action {
        flex-direction: row;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .liste .result {
        grid-template-columns: 1fr;
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {
    .liste .result {
        grid-template-columns: 1fr;
    }
}