/* TODO */

.input-group-addon {
    background-color: #F5F5F5;
    border: none;
    box-shadow: none;
    padding: 6px 21px;
    color: unset;
}

.schwarz {
    color: #000 !important;
}

.color-weiss {
    color: #fff !important;
}

.font-color {
    color: var(--font-color) !important;
}

.transparent-title {
    background-color: transparent !important;
    color: transparent !important ;
}

button {
    font-weight: 600 !important ;
}

.modal-footer.modal-footer__wk {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    border-top: none;

}

.modal-header.modal-header__wk {
    border-bottom: none;
}

.modal-footer.modal__wk .btn {
    padding: 10px 48px;
}

.modal-wk__preis p {
    font-size: 1.3rem;
}

.registrierung {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.registrierung p {
    margin: 0;
    color: var(--color-secundary);
    font-size: 22px;
}

.registrierung .panel{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border:1px solid #f5f5f5;
    background-color:#f5f5f5;
    height: 100%;
    margin: 0;
}

.registrierung .panel:hover{
    border:1px solid var(--color-secundary);
    background-color: var(--color-secundary);
}

.registrierung .panel:hover p {
    color: #fff;
}

.register__item {
    padding: 0 7px;
}

.register__item:first-child {
    padding-left: 0;
}

.register__item:last-child {
    padding-right: 0;
}

.registrierung .panel .panel-body{
    padding-bottom: 0;
}

.registrierung .panel .panel-footer{
    padding-top: 0;
    text-align: center;
    background-color: transparent;
    border-top: none;
}

.registrierung .panel .panel-footer .radio_btn{
    width:30px;
    height: 30px;
}


.registrierung .panel input[type=radio] {
    border: 2px solid var(--color-secundary);
    padding: 0.5em;
    outline: none;
    -webkit-appearance: none;
}

.registrierung .panel:hover input[type=radio] {
    border: 2px solid #fff;
}

.registrierung .panel input[type=radio]:checked {
    background: url(../images/radio_2.png) no-repeat center center;
    background-size: 14px 14px;
}

.registrierung .panel:hover input[type=radio]:checked {
    background: url(../images/radio_1.png) no-repeat center center;
    background-size: 14px 14px;
}

/*===================== Cookies-Präferenzen ==============================*/

/*
#CybotCookiebotDialog {
    background-color: var(--color-secundary) !important;
    border: 18px solid var(--color-secundary) !important;
}


#CybotCookiebotDialogBodyContentTitle {
    color:#fff !important;
}

#CybotCookiebotDialogBodyContentText * {
    color:#fff !important;
}

#td_at_b1 a.btn.btn-primary {
    color:#fff !important;
}
#CybotCookiebotDialogBodyLevelButtonsRow {
    background-color: #fff !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept, a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: var(--color-primary) !important;
    border: 1px solid var(--color-primary) !important;
    color: white !important;
}
*/

#CybotCookiebotDialogHeaderLogosWrapper {
    display: none !important;
}

.tmg_subtitle {
    display: flex;
    margin-bottom: 12px;
}

.tmg_subtitle span {
    display: block;
    min-width: 200px;
}

.forget .headline h2 {
    margin-top: clamp(1.875rem, 0.089rem + 8.93vw, 8.125rem); /* 40px - 130px */
    font-weight: 600;
    margin-bottom: clamp(1.25rem, 0.893rem + 1.79vw, 2.5rem); /* 20px - 40px */
}

.forget label {
    margin-bottom: 20px;
}

.forget .btn {
    margin-top: clamp(1.25rem, 0.656rem + 2.97vw, 3.125rem); /* 20px - 50px */
    margin-bottom: clamp(0.625rem, -0.464rem + 5.45vw, 4.063rem); /* 10px - 65px */
    padding: 16px 80px;
}

.akt-new-sale {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    z-index: 2;
    top: 10px;
    left: 10px;
}

.akt-new-sale span {
    color: #fff;
    font-size: 16px;
    margin: auto;
}

.akt-new-sale.art-aktion,
.akt-new-sale.art-new,
.akt-new-sale.art-sale {
    background-color: var(--color-secundary);
}

/*=====================/ Cookies-Präferenzen ==============================*/

@media(max-width:768px) {
    .modal-dialog  {
        width: 90%;
    }
    .modal-dialog.modal-md {
        width: 600px;
    }
    .register__item {
        margin-bottom: 10px;
    }

    .register__item:first-child {
        padding-left: 7px;
    }

    .register__item:last-child {
        padding-right: 7px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .register__item {
        margin-bottom: 15px;
    }

    .register__item:first-child {
        padding-left: 7px;
    }

    .register__item:last-child {
        padding-right: 7px;
    }
}