/* Warenkorb */

/*.table_rahmentab .form-control {
    border-color: transparent !important;
    background-color: transparent !important;
}*/

.table_rahmentab .form-control.form-control-gutschein {
    background-color: #F5F5F5 !important;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #f5f5f5;
    opacity: 1;
}

table.bg_inhalt hr,
form hr {
    border-color: transparent !important;
}

/*
.wk_bestellabschluss_bemerkung_container .ihr_zeichen {
    display: none;
}
*/

.wk__danke {
    background-color: var(--color-secundary);
    margin-top: -119px;
    aspect-ratio: 2.35 / 1 !important;
}

.wk__danke > div {
    /*height: 78vh;*/
    padding-top: 10%;
}

.wk-danke__text {
    color: #fff;
    font-family: var(--font-secundary);
    font-weight: 700;
    text-transform: uppercase;
    font-size: clamp(3.125rem, 0.781rem + 11.72vw, 12.5rem);
    /*font-size: 212px;*/
    line-height: 12rem;
    white-space: nowrap;
}

.wk-danke__text-text {
    color: #fff;
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: clamp(1.75rem, 0.614rem + 5.68vw, 4.875rem);
    /*font-size: clamp(2rem, 0.773rem + 6.14vw, 5.375rem);*/
    /*font-size: 86px;*/
}

.wk__share {
    background-color: transparent !important;
    color: var(--color-secundary) !important;
    border: none !important;
    font-size: 1.3rem;
}

.wk__share:hover {
    color: var(--color-primary) !important;
}

.warenk_table_bestellung_kopf,
.warenk_tr_bestellung_kopf {
    border-color: transparent !important;
}

.wk_container {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.wk_schnellerfassung {
    margin-bottom: 30px;
}

.wk_schnellerfassung>div {
    margin-bottom: 10px;
}

.wk_schnellerfassung .input-group>.input-group-addon {
    min-width: 100px;
}

.wk_schnellerfassung .input-group>.form-control {
    text-align: center;
}

.wk_stufen {
    background-color: var(--color-secundary);
    /*margin-top: 5px;*/
    /*margin-bottom: 0;*/
}

.wk_stufen * {
    color: #fff;
}

.wk_stufen__items {
    z-index: 3;
}

.wk_stufen__item, .wk_stufen__item a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.wk_stufen .title.active {
    font-weight: 600;
}

.wk_stufen .title {
    font-size: 1rem;
    line-height: 44px;
    padding-left: 10px;
}

.wk_footer {
    margin-top: 20px;
}

.wk_footer .checkbox label {
    line-height: 2.3;
    padding-left: 0;
}

.wk_footer>div {
    margin-bottom: 10px;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f0f0f0;
}

.wk_footer button {
    font-weight: 600;
}

.wk_login_bereich .panel .panel-title>input {
    margin-right: 10px;
}

.wk_login_bereich .panel .main {
    height: 165px;
}

.wk_login_bereich .wk_login_panel>.main>div {
    margin-bottom: 10px;
}

.wk_belegsumme {
    padding: 0;
    color: var(--font-color);
    border: none;
    background-color: transparent;
    font-weight: bold;
    text-align: right;
}

.wk_bestellabschluss_bemerkung_container {
    padding: 10px;
    border: 1px solid transparent;
}

.wk_bestellabschluss_btn>div {
    margin-bottom: 10px;
}

.wk_versandcontainer {
    background-color: transparent;
    border: transparent;
}

.wk_pos_image_container {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.wk_pos_image_container img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.wk_pos_smart_foot {
    padding: 10px;
    background-color: #eee;
}

.wk_table>thead>tr>th {
    text-align: center;
    color: var(--color-secundary);
    border-bottom: none;
}

.wk_table>tbody>tr>td {
    vertical-align: middle;
    text-align: center;
}

.wk_table .titel p {
    margin: 0;
    font-weight: bold;
    color: var(--link-color);
    text-decoration: none;
}

.wk_table td.info_spalte {
    text-align: left;
}

.wk_table div.lg_hinweis {
    text-align: left;
    color: red;
}

.belegliste_kopf_pos {
    width: 5%;
}

.belegliste_kopf_artikelnummer {
    width: 20%;
}

.belegliste_kopf_menge,
.belegliste_inhalt_menge,
.belegliste_kopf_mengeneinheit,
.belegliste_inhalt_mengeneinheit,
.belegliste_inhalt_bestand {
    text-align: center;
}

.belegliste_kopf_preiseinheit,
.belegliste_kopf_bestand,
.belegliste_inhalt_gesamtpreis,
.belegliste_kopf_gesamtpreis,
.belegliste_inhalt_rabatt,
.belegliste_kopf_rabatt,
.belegliste_inhalt_einzelpreis,
.belegliste_kopf_einzelpreis,
.belegliste_inhalt_preiseinheit {
    text-align: right;
}

.wk__rechnung {
    padding-left: 0;
}

.wk__liefer {
    padding-right: 0;
}

.wk__rechnung .form-group,
.wk__liefer .form-group {
    display: flex;
    align-items: center;
}

.wk__rechnung .form-group .control-label,
.wk__liefer .form-group .control-label {
    padding-top: 0;
}

.wk_pos_menge_zelle .input-group input.form-control {
    border: 2px solid var(--color-secundary);
    border-radius: 2rem;
}

/*.wk__adresse .form-group {
    margin: 0;
}

.wk__adresse input.form-control {
    border: none;
    box-shadow: none;
    height: unset;
    padding: 2px 0;
}

.wk__adresse .control-label {
    padding-top: 0 !important;
}

.wk__adresse .form-control[readonly] {
    background-color: #fff;
}*/

.wk__check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
    position: relative;
    border: 2px solid #1AFF80;
    padding: 14px 20px;
    border-radius: 72px;
}

.wk__check .wk__check-text{
    font-weight: 600;
}

.wk__check:hover {
    background-color: #1AFF80;
}

.wk__check:hover .wk__check-text{
    color: #0303B8;
}

.wk__check-check .liefer__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    border: 2px solid #1AFF80;
}

.b2-meldung {
    display: inline-flex;
    align-items: flex-end;
    gap: 15px;
    border-bottom: 2px solid var(--color-primary);
    margin-bottom: 5px;
}

.zb__bez {
    min-width: 160px;
}

.zb__img img {
    max-height: 26px;
}

.belegliste_inhalt_bestand {
    width: 5%;
    max-width: 5%;
}

.td_warenkorb1_untere_zeile {
    width: 650px;
}

.textfeld1 {
    height: 18px;
    font-size: 8pt;
}

.font8 {
    font-size: 8pt;
    font-weight: normal;
}

.font9 {
    font-size: 9pt;
    font-weight: normal;
}

.font10 {
    font-size: 10pt;
    font-weight: normal;
}

.font8b {
    font-size: 8pt;
    font-weight: bold;
}

.font9b {
    font-size: 9pt;
    font-weight: bold;
}

.font10b {
    font-size: 10pt;
    font-weight: bold;
}

.font9blue {
    color: #00F;
    font-size: 9pt;
    font-weight: bold;
}

.font9red {
    color: #C00;
    font-size: 9pt;
}

.font9green {
    color: #006400;
    font-size: 9pt;
}

.font9greenb {
    color: #006400;
    font-size: 9pt;
    font-weight: bold;
}

.copyright {
    font-weight: 300;
    font-size: 1rem;
}

.table_rahmentab {
    width: 100%;
    font-size: 1rem;
}

.table_mitte_inhalt {
    font-size: 1rem;
}

.bg_inhalt {
    width: 100%;
    background-color: #fff;
}

.mitte {
    width: 100%;
}

.td_mitte_inhalt_tabkopf {
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
}

.td_mitte_inhalt {
    font-size: 1rem;
}

.warenk_table_bestellflaeche {
    margin-bottom: 20px;
    padding: 2px 2px 2px 0;
}

.warenk_td_bestell_font {
    color: #000;
    font-size: 10pt;
    font-weight: 300;
}

.warenk_table_bestellung_kopf {
    margin-bottom: 3px;
    margin-top: 10px;
    padding: 3px;
    border-bottom: 1px solid black;
}

.warenk_tr_kundendaten {
    width: 100%;
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.warenk_tr_bestellung_kopf {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
    border-bottom: 1px solid black;
}

.warenk_tr_bestellung_ohneInhalt {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
    border-spacing: 3px;
    border-collapse: separate;
}

.warenk_table_bestellung_fuss {
    margin-top: 3px;
    margin-bottom: 3px;
}

.warenk_td_bestell_buttons {
    margin-top: 5px;
    font-size: 9pt;
    font-weight: normal;
}

.seiten_ueberschrift {
    width: 100%;
    font-size: 12pt;
    font-weight: 300;
}

.td_formular_kopf {
    color: #000;
    font-size: 10pt;
    font-weight: 300;
}

.td_formular_beschriftung {
    width: 120px;
    font-size: 1rem;
}

.td_formular_text {
    font-size: 1rem;
    font-weight: 300;
}

.beleg_mitte {
    margin-top: 7px;
    padding: 5px;
    background-color: #fff;
}

.fontbelegarchiv {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.warenk_lieferbedingung {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.beleg_lfb_texte_table {
    width: 100%;
}

.beleg_lfb_texte_td1 {
    font-size: 9pt;
    font-weight: 300;
}

.beleg_lfb_texte_td2 {
    font-size: 9pt;
    font-weight: normal;
}

.td_warenkorb1_untere_zeile {
    width: 65%;
    font-size: 1rem;
}

.meldungstext {
    color: #f00;
}

.tr_mitte_inhalt_0 {
    padding: 10px 0;
}

.warenk_td_summen {
    padding-left: 50px;
}

#sm_regneu,
#Zurueck,
#Bestellen,
#Verwerfen,
#Aendern,
#ZurueckFensterZu,
#sm_los_pw {
    width: 150px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 2.5;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: var(--color-primary);
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK,
#NeukundeRegistrieren,
#Anmelden,
#Abmelden {
    float: right;
    width: 148px;
    height: 33px;
    margin-top: 5px;
    margin-right: 5px;
    padding: 0 5px 8px 5px;
    line-height: 2.5;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: var(--color-primary);
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK2,
#WeiterWK3_ak,
#Drucken {
    float: right;
    width: 150px;
    height: 30px;
    margin-right: 12px;
    line-height: 2.5;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: var(--color-primary);
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK3_nk {
    width: 150px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 2.5;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: var(--color-primary);
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
}

input.textfeld_wk_regstartbereich,
input#Artikelnummer_suche,
input#wk1_a_textfeld1,
input.textfeld_registrieren,
input.textfeld1,
input.regi_textfeld_al,
select.select_registrieren,
select.regi_select_al,
select.select_zahlungsarten,
select.select_beleg2 {
    display: block;
    height: 30px;
    padding: 0 12px;
    line-height: 2.5;
    /*line-height: 1.42857143;*/
    font-size: 1.16rem;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

select.select_registrieren,
select.regi_select_al {
    width: 200px;
}

#tx_pw1,
#tx_pw2 {
    max-width: 200px;
}

input.button_normal,
input#uebernehmen,
input#sm_import,
input#zahlen {
    width: 148px;
    height: 30px;
    margin-top: 5px;
    line-height: 2.5;
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    -webkit-appearance: none;
}

input.button_normal#SeiteDrucken {
    float: right;
    margin: 2px;
}

input.button_normal#Zu {
    float: left;
    margin: 2px;
}

.wk_footer .checkbox a {
    color: var(--link-color);
    text-decoration: underline;
}

#jqKostenstelleCombo {
    display: flex;
    width: 100%;
}

#jqKostenstelleComboInput {
    width: 100%;
    margin-left: 0;
}

#jqKostenstelleCombo input {
    min-width: auto;
}

#jqKostenstelleCombo button {
    margin-left: 0;
}

.wk__delete {
    border: 2px solid #0303b8;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .warenk_table_bestellflaeche,
    .belegliste_kopf_preiseinheit,
    .belegliste_inhalt_preiseinheit,
    .belegliste_kopf_mengeneinheit,
    .belegliste_inhalt_mengeneinheit,
    .belegliste_kopf_bestand,
    .belegliste_inhalt_bestand,
    .belegliste_kopf_rabatt,
    .belegliste_inhalt_rabatt,
    .belegliste_kopf_pos,
    .belegliste_inhalt_pos,
    .belegliste_kopf_gesamtpreis,
    .belegliste_inhalt_gesamtpreis {
        display: none;
    }
    /*.wk-danke__text {
        font-size: 60px;
    }*/
    .wk_stufen__items {
        line-height: 40px;
    }
}

/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    /*.wk-danke__text {*/
    /*    font-size: 120px;*/
    /*}*/
}

/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    /*.wk-danke__text {*/
    /*    font-size: 160px;*/
    /*}*/
}
