/* Filter */

.filter {
    margin-bottom: 25px;
}

.filter__head {
    margin: 0 0 15px 0;
    color: #fff;
    background: var(--color-primary);
    border-bottom: thin solid #000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
}

.filter__title {
    display: block;
    padding: 10px 50px 10px 15px;
}

.filter__caret {
    margin-left: auto;
    font-size: 1.3rem;
    color: #fff;
}

.filter__reset {
    padding-left: 5px;
    font-size: 1.3rem;
    color: #fff;
}

.filter__body {
    display: none;
    width: calc(100%);
    margin: 0 0;
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    border: thin solid var(--color-primary);
}

.filter__body-open {
    display: block;
}

.filter__body-absolute {
    margin-top: -15px;
    margin-bottom: 10px;
}

.filter__body .xxx--list {
    margin: 5px 0;
}

.filter__option {
    display: block;
    margin: 5px 0;
}

.filter__label {
    position: relative;
    width: 100%;
    padding-left: 10px;
}

.filter__icon,
.filter__value {
    color: #666;
}

.filter__label:hover .filter__icon,
.filter__label:hover .filter__value,
.filter__option-active .filter__icon,
.filter__option-active .filter__value {
    color: #000;
}

.filter__count {
    position: absolute;
    top: 4px;
    right: 15px;
    color: #999;
    font-weight: lighter;
    font-size: 0.8rem;
}
