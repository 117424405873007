/* Accordion */

/* NAV */
.xxx--elastic-search-nav {
    margin-bottom: 15px;
}

.xxx--elastic-search-nav--title {
    padding: 7px 15px;
}

.accordion {
    margin-bottom: 10px;
}

.es-nav {
    background: #fff;
}

.accordion__item {
    position: relative;
}

.accordion .accordion__item > a,
.accordion .accordion__item > div > a{
    display: block;
    line-height: 30px !important;
}

.accordion .accordion__item .accordion__link {
    border-bottom: 2px solid var(--color-primary);
    color: var(--font-color);
}

.accordion .accordion__item .accordion__link:hover {
    color: var(--color-primary);
}

.accordion__item:last-child a {
    border-bottom: none;
}

span.accordion__title {
    font-size: 1.25rem;
    font-weight: 700;
}

.accordion__link,
.accordion__label,
.accordion__count,
.accordion__caret{
    transition: background 300ms ease, border 300ms ease, color 300ms ease, padding 300ms ease, margin 300ms ease, top 300ms ease, left 300ms ease, right 300ms ease, bottom 300ms ease;
}

.accordion__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 2rem !important;
}

.accordion__label {
    padding-right: 0.5rem;
    font-size: 1.25rem;
    color: #666;
}

.accordion__link:hover .accordion__label {
    color: #111;
}

.accordion__count {
    color: #aaa;
    font-size: 1rem;
}

.accordion__link:hover .accordion__count {
    color: #999;
}

.accordion__caret {
    width: 16px;
    height: 16px;
    margin-left: auto;
}

.accordion__link .xxx---is-active .accordion__label,
.accordion__link .xxx---is-active .accordion__count{
    color: var(--color-primary);
}

.accordion__item a.active .accordion__label,
.accordion__item a.active .accordion__count {
    color: var(--color-primary);
}

.accordion__item .accordion__nav .sidemenu_ug_row .accordion__link .accordion__label,
.accordion__item .accordion__nav .sidemenu_ug_row .accordion__link .accordion__count {
    color: #666;
}
.accordion__item .accordion__nav .sidemenu_ug_row a.active .accordion__label,
.accordion__item .accordion__nav .sidemenu_ug_row a.active .accordion__count {
    color: var(--color-primary);
}

.accordion__caret svg {
    height: 16px;
    width: 16px;
}

.accordion__caret.down .svg-icon-plus,
.accordion__caret.up .svg-icon-minus {
    display: flex;
}

.accordion__caret.down .svg-icon-minus,
.accordion__caret.up .svg-icon-plus {
    display: none;
}

.accordion__link {
    display: flex !important;
}
