/* HEADER */

.xxx--page-header--menu {
    background-color: #f0f0f0;
}

.xxx--page-header--block {
    background-color: var(--color-primary);
}

.xxx--page-header {
    position: relative;
}

.header-top__menu .navbar-nav.header__nav {
    gap: 0;
}

.header__nav li.header-nav__item {
    /*margin-left: 5px;*/
}

.header__nav li.header-nav__item a {
    padding: 0 14px;
}

.header-top__menu ul li.header-nav__item > a:empty {
    display: none;
}

.header-top__menu ul li.header-nav__item > a {
    line-height: 56px;
}

.header-top__menu ul li.header-nav__item .info__menu.info__menu-lang li {
    max-height: 30px;
}

.header-top__menu ul li.header-nav__item .info__menu.info__menu-lang li .xxx--language--block {
    line-height: 20px;
}

.header__nav li.user__li a {
    line-height: 30px;
}

.header__nav .info__hover {
    position: absolute;
    right: 0;
    top: 55px;
    display: none;
}

.header__nav .header-nav__item .info__menu {
    position: absolute;
    display: block;
    right: 0;
    top: 55px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    z-index: 100;
}

.header-nav__item:hover .info__konto {
    position: absolute;
    top: 55px;
    display: block;
    padding: 15px;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    z-index: 3;
    /*min-width: 170px;*/
    /*max-width: 340px;*/
}

.header-nav__item:hover .info__lang {
    position: absolute;
    top: 55px;
    display: block;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    z-index: 2;
    /*min-width: 60px;*/
}

.sm__menu {
    top: 100%;
    left: 0;
    width: 0;
    overflow: hidden;
    background-color: #fff;
    transition: width .5s ease, padding .5s ease, background-color .5s ease;
    z-index: 100;
}

.sm__menu.showmenu {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    transition: width .5s ease, padding .5s ease, background-color .5s ease;
}

.sm__menu * {
    /*color: #fff !important;*/
}

.navbar.navbar-default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}

/*.header__language:after {
    font-family: "Font Awesome 6 Free";
    content: "\f107";
    display: inline-flex;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    color: #fff;
}*/

.header__language:hover:after {
    content: '';
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    /*.xxx--page-header--menu {
        background-color: var(--color-secundary);
    }*/
    /*.navbar-default .navbar-toggle .icon-bar {
        background-color: #fff !important;
    }*/
    .header-nav__item:hover .info__konto {
        display: flex;
        padding: 8px;
        z-index: 2;
        min-width: 100px;
    }
    .navbar.navbar-default {
        display: block;
    }
}

/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    /*.xxx--page-header--menu {
        background-color: var(--color-secundary);
    }*/
    /*.navbar-default .navbar-toggle .icon-bar {
        background-color: #fff !important;
    }*/
    .navbar.navbar-default {
        display: block;
    }
}

