/* FORMULAR */

.register__agb ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.register__agb ul li {
    display: flex;
}

.register__agb .agb__link:last-child span.agb-link__komma,
.register__agb .agb__link:nth-last-of-type(2) span.agb-link__komma {
    display: none;
}

.register__agb .agb__link span.agb-link__und {
    display: none !important;
}

.register__agb .agb__link:last-child span.agb-link__und {
    display: inline-block !important;
}

.captcha__block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.captcha__img {
    background-color: #fff;
    padding: 0.5rem 0;
    /*margin-bottom: 10px;*/
    /*border: 1px solid #ccc;*/
    /*min-width: 240px;*/
}

.captcha__input {
    max-width: 240px;
}

.form-col {
    column-count: 2;
}

.form-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 2%;
}

.feld-w-100.feld-w-50 {
    width: 49% !important;
}

.feld-w-100 {
    width: 100% !important;
}

.form__items .form__item {
    display: grid;
    /*grid-template-columns: 1fr 3fr;*/
    gap: 20px;
}

.form__label {
    /*height: 76px;*/
}

.form__label,
.formular__label.formular-label__top .form__label {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
}

.form__label > span {
    text-align: end;
    font-weight: 500;
}

.form__label > span:empty {
    display: none;
}

.formular__label.formular-label__top .form__label span {
    text-align: start;
}

.formular__label .form__label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0 20px;
}

.form__label sup {
    font-size: inherit;
    margin-left: 5px;
}

.help-block {
    min-height: 22px;
    margin: 0;
}

.form-control {
    border: 1px solid #F5F5F5;
    background-color: #F5F5F5;
    box-shadow: none;
}

.captcha__block .svg-icon {
    height: 24px;
    width: 24px;
}

.captcha__block input.form-control {
    border: 2px solid var(--color-secundary);
    border-radius: 0 72px 72px 0;
}

/*
*
* Extra Small (XS)
*/

@media (min-width: 767px) {
    .form__items.formular__spalten {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 48%));
        gap: 0 2%;
    }
}

