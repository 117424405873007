/* Mikst */

.modal-header.login::before,
.modal-header.login::after {
    content: none;
}

.modal-header.login {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-body .svg-icon {
    height: 20px;
    width: 20px;
}

/*.modal-body .input-group {
    display: flex;
}*/


.bg-primary {
    background-color: var(--color-primary) !important;
}

.bg-secundary {
    background-color: var(--color-secundary) !important;
}

.bg__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    padding-top: 100%;
    /*max-width: 100%;*/
}

.a-ratio-1 {
    aspect-ratio: 1;
}

.a-ratio-4-3 {
    aspect-ratio: 4/3;
}

.a-ratio-16-9 {
    aspect-ratio: 16/9;
}

.a-ratio-16-5 {
    aspect-ratio: 16/5;
}

.content-none::after,
.content-none::before {
    content: none;
}

.form-control:focus {
    border-color: var(--color-primary);
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px var(--color-primary);
}

.through {
    text-decoration: line-through !important;
}

.schwarz-weiss {
    filter: grayscale(100%);
    pointer-events: none;
}

.tooltip.finn-tooltip .tooltip-inner {
    color: var(--color-secundary);
    border: 1px solid #fff;
    background-color: var(--color-button);
    padding: 8px 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

/* Hide arrow */
.tooltip.finn-tooltip .tooltip-arrow {
    display: none;
}

/*===================== Checkbox ==============================*/

.checkbox__default {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.checkbox__default:focus + .checkbox__custom {
    box-shadow: 0 0 0 1px #38577a, 0 0 10px #7e9bbd;
}

.checkbox__custom {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid var(--color-secundary);
    border-radius: 2px;
    vertical-align: sub;
    margin-right: 5px;
    transition: background 0.2s ease-in;
}

.checkbox__custom::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url('../images/svg/check.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.2s ease-in;
}

.wk_footer .checkbox__custom::before {
    background-image: url('../images/svg/check.svg');
}

/*
.wk_footer .checkbox {
    padding-left: 25px;
}
*/

.checkbox__default:checked + .checkbox__custom {
    background-color: transparent;
}

.checkbox__default:checked + .checkbox__custom::before {
    transform: translate(-50%, -50%) scale(1);
    /*border: 1px solid var(--color-primary);*/
}

.checkbox__default:checked + .checkbox__custom {
    border: 1px solid var(--color-secundary);
}

/*=====================/ Checkbox ==============================*/

/*===================== Radiobutton ==============================*/

.radio__default {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.radio__custom {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 2px solid #ccc;
    border-radius: 50%;
    vertical-align: text-top;
    margin-right: 5px;
}

.radio__custom::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: var(--color-primary);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.2s ease-in;
}

.radio__default:checked + .radio__custom::before {
    /* display: block; */
    transform: translate(-50%, -50%) scale(1);
}

.radio__default:focus + .radio__custom {
    box-shadow: 0 0 0 1px #38577a, 0 0 10px #7e9bbd;
}

/*=====================/ Radiobutton ==============================*/


/*
 *
 * Sonstiges (MISC)
 */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.carousel-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    margin: 0;
    width: 100%;
}

.carousel-indicators li {
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1px solid var(--color-secundary);
}

.carousel-indicators .active {
    width: 18px;
    height: 18px;
    background-color: var(--color-secundary);
}

.italic {
    color: #ccc;
    font-style: italic;
}

img.img-thumbnail {
    width: 100%;
}

.red {
    border: 1px solid red;
}

.blue {
    border: 1px solid blue;
}

.yellow {
    border: 1px solid yellow;
}

.green {
    border: 1px solid green;
}

.circle_red,
.circle_red span,
.xxx--product--availability---r,
.xxx--product--availability---r span {
    color: var(--bestand-r);
}

.circle_yellow,
.circle_yellow span,
.xxx--product--availability---y,
.xxx--product--availability---y span {
    color: var(--bestand-y);
}

.circle_green,
.circle_green span,
.xxx--product--availability---g,
.xxx--product--availability---g span {
    color: #00B050;
}

.carousel-control {
    width: 3%;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none;
}

span.modal_close_button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-color: var(--color-secundary);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 50%;
    color: var(--color-primary);
    cursor: pointer;
}

form.login_modal_form > .input-group > .input-group-addon {
    color: var(--color-primary);
    background-color: transparent;
}


div.galerie_caption.gal_slide {
    margin: 20px;
}

.modal_img_container {
    position: relative;
    width: 100%;
    height: 500px;
}

.modal_img_container > picture > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

#modalimage_gal-description {
    margin-top: -18px;
}

#modalimage_gal-description span {
    padding: 5px 10px 8px 10px;
    border-radius: 5px;
    background-color: #fff;
}

label.form_elem_desc,
div.labelb {
    padding: 0 10px;
    color: var(--font-color);
    font-style: italic;
}

p.labelb {
    color: var(--font-color);
    font-style: italic;
}

input.form_elem_div.captchacode {
    width: 100px;
}

.itemnichtsichtbar {
    display: block;
    height: auto;
    border: 1px dotted #c0c0c0;
}

.itemnichtsichtbar h1,
.itemnichtsichtbar h4,
.itemnichtsichtbar h4 a,
.itemnichtsichtbar h5,
.itemnichtsichtbar p,
.itemnichtsichtbar a,
.itemnichtsichtbar div a,
.itemnichtsichtbar div label {
    color: #c0c0c0;
}

.adresse {
    margin-bottom: 15px;
}

.adresse div.galbild_vorsch {
    margin-bottom: 15px;
}

.Hoehe {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
}

.category-list,
.ranges-list,
.tags-list {
    padding-left: 10px;
    font-size: smaller;
    list-style: none;
}

.category-list li,
.ranges-list li,
.tags-list li {
    margin-left: 0;
    margin-bottom: 5px;
}

.artikelmodal_bildcontainer {
    position: relative;
    padding-top: 100%;
    width: 100%;
    /*border: 1px solid var(--font-color);*/
    border-radius: 4px;
    background-color: var(--color-grau);
}

.artikelmodal_bildcontainer > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.category-list li ul {
    padding-left: 10px;
    border-left: 1px solid #ddd;
    list-style: none;
}

.category-list li a.active {
    font-weight: bold;
}

.category-list li a span {
    padding-left: 5px;
    color: gray;
    font-size: x-small;
}

.ranges-list li label {
    font-size: small;
    font-weight: normal;
}

.tags-list li label {
    padding-left: 5px;
    font-size: small;
    font-weight: normal;
}

.tags-list li input {
    margin-right: 5px;
}

.mengenformel_modal .modal-body .input-group .input-group-addon {
    min-width: 100px;
}

.mengenformel_modal .modal-body .input-group .form-control {
    max-width: 100px;
}

.btn-link {
    color: var(--color-primary);
}

/*
 *
 * Hersteller
 */

.hersteller {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hersteller img {
    /*max-width: 150px;
    max-height: 46px;*/
    width: 100%;
    height: 50px;
    object-fit: contain;
}


.hersteller p {
    font-size: 1rem;
}


/*
 *
 * RSS
 */

div.rss-items {
    border-top: 1px solid grey;
}

div.rss-items h6 {
    font-weight: bold;
}

div.rss table {
    width: 100%;
    margin-bottom: 10px;
}

div.rss a {
    text-decoration: underline;
}

div.rss-items p:nth-child(4) {
    color: grey;
    font-style: italic;
    text-align: right;
}

.rss .row {
    margin-left: 0;
}

.rss-text {
    padding-left: 0;
}

.rss-text img {
    float: left;
    width: 25%;
    margin-right: 10px;
    vertical-align: middle;
}

.rss-items {
    cursor: pointer;
}

.rss-items .rss-date,
.rss-items .btn-primary {
    float: right;
}

/*
 *
 * ELEMENTS
 */

iframe {
    max-width: 100%;
    height: auto;
    /*aspect-ratio: 16 / 9 !important;*/
}

/*iframe html,
iframe body {
    height: 100% !important;
    min-height: 500px;
}*/


iframe[ng-src*="3xios/beleg1.php"],
iframe[ng-src*="3xios/beleg2.php"]{
    min-height: 500px;
}


/**
 *
 * @package bootstrap
 * @extension
 */

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-fat {
    font-weight: bold;
}


/**
 * language
 * @package xxx
 * @component
 */

.xxx--language li {
    text-align: center;
}

.xxx--language--list .xxx--list-item + .xxx--list-item {
    margin-left: 10px;
}

.xxx--language--block {
    opacity: .3;
    background: transparent;
    border-radius: 5px;
    overflow: hidden;
}

.xxx--scroll-sticky .xxx--language--list {
    position: absolute;
    right: 0;
    z-index: 1000;
}

.xxx--language--block.xxx---is-active,
.xxx--language--block:hover {
    opacity: 1;
    transition: opacity 300ms ease;
}

.xxx--language--label {
    font-size: 1rem;
    color: var(--color-secundary);
    text-transform: uppercase;
}

/**
 * list
 * @package xxx
 * @component
 */

.xxx--list---cleared {
    list-style: none;
    padding: 0;
    margin: 0;
}

.xxx--list---level .xxx--list---level {
    padding-left: 15px;
}

.xxx--list-item > .xxx--collapse {
    /*border-top: thin solid #666;*/
    /*border-bottom: thin solid #666;*/
}

.xxx--list-item > .xxx--collapse.xxx---is-collapsed {
    border-top: thin solid #fff;
    border-bottom: thin solid #fff;
}

/*
.xxx--list-item:last-of-type>.xxx--collapse {
    border-bottom: thin solid #fff !important;
}
*/

/**
 * product
 * @package xxx
 * @component
 */

.xxx--product--sku:before {
    content: '# ';
}

.xxx--product--sku {
    font-size: 0.8rem;
    color: #999;
}

/* collapse
* @package xxx
* @component
*/

.xxx--collapse {
    max-height: 1000px;
    overflow: hidden !important;
    transition: max-height 2000ms ease, padding-top 1000ms ease, margin-bottom 1000ms ease, opacity 1000ms ease, border 1000ms ease, background 1000ms ease, border 1000ms ease !important;
}

.accordion__nav {
    max-height: 1000px;
    overflow: hidden !important;
}


.xxx--collapse.xxx---is-collapsed {
    max-height: 0 !important;
}

.xxx--collapse.xxx---is-expanded {
    max-height: 3000px;
    overflow: visible !important;
}

.accordion__nav.xxx---is-collapsed {
    max-height: 0 !important;
}

.accordion__nav.xxx---is-expanded {
    max-height: 3000px;
    overflow: visible !important;
}


/**
 * scroll-sticky
 * @package xxx
 * @component
 */

.xxx--scroll-sticky {
    position: fixed !important;
    top: 0;
    z-index: 1000;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .5);
}

.xxx--scroll-sticky .navbar-logo {
    display: block !important;
    height: 45px;
}

.xxx--scroll-sticky .xxx--page-header--img {
    display: none;
}

.xxx--scroll-sticky .xxx--page-header--block {
    padding: 25px 0 0 0;
}

/**
 * v-card
 * @package xxx
 * @component
 */

.xxx--v-card {
    background: #fff;
    border: thin solid #ccc;
    border-radius: 5px;
}

.xxx--v-card--info {
    margin-top: 15px;
}

.xxx--v-card--image {
    max-width: 100%;
    padding: 15px;
    background: #ccc;
}

*[class*="xxx--v-card--"][class*="-text"],
*[class*="xxx--v-card--"][class*="-address-"] {
    font-size: 1rem;
}


/**
 *
 * @package xxx
 * @modifier
 */

.xxx---shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, .4);
}

.xxx---relative {
    position: relative;
}

.xxx---absolute {
    position: absolute;
}

.xxx---absolute-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.xxx---block,
.xxx---block-inside > * {
    display: block !important;
}

.xxx---inline,
.xxx---inline-inside > * {
    display: inline-block !important;
}

.xxx---none {
    display: none !important;
}

.xxx---center {
    margin-top: 50% !important;
    margin-left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.xxx---square {
    position: relative;
    display: block;
    width: 100%;
}

.xxx---square:after {
    display: block;
    padding-bottom: 100%;
    content: '';
}

.xxx--square--outer {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.xxx--square--inner {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 15px;
    background: #fff;
    text-align: center;
}

.xxx--square--image {
    max-width: 100%;
    max-height: 100%;
    margin-top: 50%;
    transform: translateY(-50%);
}

.xxx---square-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.xxx---square-background-colored {
    background-color: #ccc;
    outline: thin solid #ccc;
}

.xxx---clear {
    clear: both !important;
}

.xxx---full-width,
.xxx---w-100 {
    width: 100% !important;
}


.xxx---no-margin {
    margin: 0 !important;
}

.xxx---no-padding {
    padding: 0 !important;
}

.display-flex {
    display: flex !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}


#backtop {
    z-index: 10002;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    transition: all .3s ease-out;
    position: fixed;
}

#backtop button {
    color: var(--color-primary);
    background-color: transparent;
    outline: none;
    border: 5px;
    /* text-shadow: 1px 1px 7px #fff; */

}

#backtop button:hover {
    cursor: pointer;
    background-color: transparent;

}

#backtop button.btn:active {
    box-shadow: none;
}

#backtop button.btn-default:focus {
    color: var(--color-secundary) !important;
}

.fa-fw {
    color: var(--color-primary);
    margin-bottom: 25px;
    width: 2em;
}

.show {
    opacity: 1 !important
}

.form-group.kostenstelle {
    margin: 5px 0;
}

.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        padding-left: 30px;
    }

    .artikelliste_liste_bildcontainer {
        position: relative;
        width: 100%;
        /* height: 200px; */
        border: 1px solid rgb(180, 180, 180);
        border-radius: 4px;
    }

    .sidemenu {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 0 !important;
        display: block !important;
        background-color: #FFF;
        z-index: 1000;
        -webkit-box-shadow: -10px 10px 15px 5px rgb(170 170 170 / 40%);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgb(170 170 170 / 40%);
    }

    .close-button {
        text-align: right;
        padding: 5px 2px;
        min-width: 275px;
    }

    .zukueckbtn {
        margin-bottom: 10px;
    }
    .carousel-indicators li {
        width: 15px;
        height: 15px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {

    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        float: none;
    }

    .navbar-header-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon:before,
    .navbar-header-icon:after {
        content: none;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        position: absolute;
        z-index: 10000;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    }

    .navbar_mobile_umenu {
        float: right;
        width: 40%;
        padding: 10px;
        background: #fff;
    }

    .navbar-nav > li {
        float: unset;
    }

   /* .navbar-nav {
        width: 100%;
    }*/

    .navbar-nav .open .dropdown-menu {
        float: none;
        display: block;
        position: static;
        width: auto;
        margin-top: 0;
        border: 0;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .navbar-header {
        display: flex;
        flex-direction: column;
    }

    .navbar__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .anhang-tab__items {
        gap: 8px;
    }

    .anhang-tab__item {
        max-height: 60px;
    }
}

