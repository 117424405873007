/* Galerie */

.gallist img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gal-col {
    display: grid;
    gap: 30px 2%;
}

.gal-col-1 {
    grid-template-columns: 1fr;
}

.gal-col-2 {
    grid-template-columns: repeat(2, 1fr);
}

.gal-col-3 {
    grid-template-columns: repeat(3, 1fr);
}


.gal-col-4 {
    grid-template-columns: repeat(4, 1fr);
}

.gal-col-5 {
    grid-template-columns: repeat(5, 1fr);
}


.gal-col-6 {
    grid-template-columns: repeat(6, 1fr);
}

div.gal_list div.galerie_caption {
    height: 1.5rem;
    max-height: 1.5rem;
    line-height: 1.5rem;
    padding: 0;
}

.fliesen__list{
    column-gap: 1rem;
    margin: 0 auto;
}

.fliesen__list.spalten-2 {
    column-count: 2;
}
.fliesen__list.spalten-3 {
    column-count: 3;
}
.fliesen__list.spalten-4 {
    column-count: 4;
}
.fliesen__list.spalten-5 {
    column-count: 5;
}
.fliesen__list.spalten-6 {
    column-count: 6;
}

.fliesen__item {
    display: inline-block;
    background: var(--color-primary);
    padding: 1rem;
    margin: 0 0 1rem;
    width: 100%;
    box-sizing: border-box;
}
.fliesen__item img{
    width: 100%;
}

.open-modal-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .fliesen__list.spalten-2,
    .fliesen__list.spalten-3,
    .fliesen__list.spalten-4,
    .fliesen__list.spalten-5,
    .fliesen__list.spalten-6 {
        column-count: 2;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .fliesen__list.spalten-2 {
        column-count: 2;
    }
    .fliesen__list.spalten-3 {
        column-count: 3;
    }
    .fliesen__list.spalten-4,
    .fliesen__list.spalten-5,
    .fliesen__list.spalten-6 {
        column-count: 4;
    }
}


@media all and (-ms-high-contrast:none) {
    /* IE11 */
    *::-ms-backdrop,
    .gallist div.galbild_vorsch img {
        height: auto;
        position: absolute;
        bottom: 0;

    }
    *::-ms-backdrop,
    .gallist div.galbild_vorsch {
        background-color: #e6e6e6;
        border-radius: 4px;
    }
    /* IE11 */
}

