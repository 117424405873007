/* Portal */

.xxx--portal+.xxx--portal {
    margin-top: 30px;
}

.headline {
    display: inline-block;
    margin-bottom: 2rem;
}

.headline h1 {
    color: var(--color-secundary);
    /*text-decoration: underline;*/
    font-weight: 600;
    /*text-transform: capitalize;*/
    font-size: 1.3rem;
}

.headline h1:first-letter {
    text-transform: uppercase;
}

.headline h2 {
    margin-top: 6px;
}

.headline hr {
    width: 70px;
    height: 4px;
    background-color: var(--color-secundary);
    border-radius: 4px;
    margin: 0;
}

.mod__block {
    position: relative;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
}

.mod__block+.mod__block {
    margin-top: 50px;
}


.mod__link {
    position: relative;
    display: block;
    min-height: 60px;
    margin: 0 15px 0 0;
    overflow: hidden;
    border: thin solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    transition: box-shadow 300ms ease;
    aspect-ratio: 4/3;
}

.mod__link.link__square {
    aspect-ratio: 1;
}


.mod__link:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .6);
}

.link__label {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    color: var(--color-primary);
    background: rgba(255, 255, 255, .9);
    transition: padding 300ms ease;
}

.mod__link:hover .link__label {
    padding: 30px 15px;
}

.link__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.link__image {
    height: 100%;
    width: 100% ;
    object-fit: contain;
}

.link__background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.cover .link__background {
    background-size: cover !important;
}

/*===================== Portal-Grid ==============================*/

.grid .grid__items {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /*grid-auto-rows: 1fr;*/
    grid-template-areas:
        "item1 item1 item2 item4 item4 item5"
        "item1 item1 item3 item4 item4 item6"
        "item7 item9 item9 item10 item12 item12"
        "item8 item9 item9 item11 item12 item12"
    ;
    gap: 0;
}

.grid .grid__item {
    aspect-ratio: 1;
    display: flex;
    position: relative;
    background-blend-mode: darken;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all 0.8s ease;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
}

.grid .grid__item .galbild_vorsch {
    aspect-ratio: 1;
}

.grid .grid__item .grid__wrapper {
    width: 100%;
    max-width: 100%;
    min-height: 1px;
    border-radius: 0;
}

.grid .grid__item:nth-child(1) {
    grid-area: item1;
}
.grid .grid__item:nth-child(2) {
    grid-area: item2;
}
.grid .grid__item:nth-child(3) {
    grid-area: item3;
}
.grid .grid__item:nth-child(4) {
    grid-area: item4;
}
.grid .grid__item:nth-child(5) {
    grid-area: item5;
}
.grid .grid__item:nth-child(6) {
    grid-area: item6;
}
.grid .grid__item:nth-child(7) {
    grid-area: item7;
}
.grid .grid__item:nth-child(8) {
    grid-area: item8;
}
.grid .grid__item:nth-child(9) {
    grid-area: item9;
}
.grid .grid__item:nth-child(10) {
    grid-area: item10;
}
.grid .grid__item:nth-child(11) {
    grid-area: item11;
}
.grid .grid__item:nth-child(12) {
    grid-area: item12;
}

.grid .grid__item .knt__button,
.grid .grid__item p.teasertext,
.grid .grid__item .grid__text .kachel__artnummer,
.grid .grid__item .grid__text .result__zusatz,
.grid .grid__item .grid__text .result__langtext,
.grid .grid__item .grid__text .kachel__bottom,
.grid .grid__item .grid__action {
    display: none;
}


.grid .grid__item img {
     aspect-ratio: 1 !important;
}

.grid .grid__item .grid__filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-secundary);
}

.grid .grid__item:nth-child(1) .grid__filter,
.grid .grid__item:nth-child(4) .grid__filter,
.grid .grid__item:nth-child(7) .grid__filter,
.grid .grid__item:nth-child(10) .grid__filter {
    opacity: 0.5;
    transition: all 0.8s ease;
}

.grid .grid__item:nth-child(2) .grid__filter,
.grid .grid__item:nth-child(5) .grid__filter,
.grid .grid__item:nth-child(8) .grid__filter,
.grid .grid__item:nth-child(11) .grid__filter{
    opacity: 0.3;
    transition: all 0.8s ease;
}

.grid .grid__item:nth-child(3) .grid__filter,
.grid .grid__item:nth-child(6) .grid__filter,
.grid .grid__item:nth-child(9) .grid__filter,
.grid .grid__item:nth-child(12) .grid__filter {
    opacity: 0.7;
    transition: all 0.8s ease;
}

.grid .grid__item:hover .grid__filter {
    opacity: 0;
}

.grid .grid__item .grid__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    background-color: rgba(123,190,168, .4);
    transition: all 0.8s ease;
    padding: 10px;
}

.grid .grid__item:hover .grid__text {
    background-color: rgba(123,190,168, 1);
}

.grid .grid__item .grid__text .grid__titel {
    margin: 0;
    font-size: 1rem;
    padding: 0;
    color: var(--grid-color);
    font-weight: 500;
    min-height: 1px;
}

.grid .grid__item:hover {
    box-shadow: var(--box-shadow);
}

.grid .grid__item:hover .grid__text .grid__titel {
    color: #fff;
}

.teasertext.teaser-1,
.teasertext.teaser-2,
.teasertext.teaser-3,
.teasertext.teaser-4,
.teasertext.teaser-5,
.teasertext.teaser-6 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.teasertext.teaser-1 {
    min-height: 1.5rem;
    -webkit-line-clamp: 1;
}

.teasertext.teaser-2{
    min-height: 3rem;
    -webkit-line-clamp: 2;
}

.teasertext.teaser-3 {
    min-height: 4.4rem;
    -webkit-line-clamp: 3;
}

.teasertext.teaser-4 {
    min-height: 5.8rem;
    -webkit-line-clamp: 4;
}

.teasertext.teaser-5 {
    min-height: 7.2rem;
    -webkit-line-clamp: 5;
}

.teasertext.teaser-6 {
    min-height: 8.7rem;
    -webkit-line-clamp: 6;
}


/*=====================/ Portal-Grid ==============================*/


/* XXS */
@media (max-width: 359px) {
    .mod__block .xxx--list-item {
        width: 100%;
    }
}


/* XS */
@media (max-width: 767px) {
    .xxx--portal-mod--block--header {
        margin-top: 30px;
    }

    .xxx--portal-mod--block--headline,
    .xxx--portal-mod--block--teaser-text {
        text-align: center;
    }
    .grid .grid__items {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .grid .grid__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        /*grid-template-rows: repeat(6, 1fr);*/
        grid-template-areas:
        "item1 item1 item2"
        "item1 item1 item3"
        "item4 item6 item6"
        "item5 item6 item6"
        "item7 item7 item8"
        "item7 item7 item9"
        "item10 item12 item12"
        "item11 item12 item12"
    ;
        /*background-color: rgba(0, 0, 0, 0.8);*/
        gap: 0;
    }
}

