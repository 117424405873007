/* Merkzettel */

.mz__titel {
    font-size: 1.2rem;
    font-weight: 500;
}

.merkzettel__info a h4 {
    font-size: 1.16rem;
    min-height: 30px;
}

.merkzettel__info a h4:hover {
    color: var(--color-primary);
}

.merkzettel .result-btn__merkzettel,
.merkzettel .result-btn__detail,
.merkzettel .result-btn__anfrage{
    display: none;
}

.merkzettel .result__action .svg-icon.svg-icon__mz {
    display: block;
}

.modal-body .btn {
    font-size: 14px;
}

.modal__mz .wk__delete {
    height: 42px;
    width: 42px;
    background-color: #fff !important;
}
