/* SUGGESTION */

.suggestion-list__block {
    position: absolute;
    left: 0;
    top: 34px;
    display: block;
    padding: 0;
    z-index: 1000;
    background: #fff;
}

.suggestion-list__label {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background: var(--color-primary);
}

.suggestion__list {
    width: 100%;
    padding: 0;
    background: #fff;
    z-index: 100;
}

.suggestion__list+.suggestion__list {
    border-top: 2px solid var(--color-primary);
}

.suggestion-list__link {
    padding: 10px 15px;
    font-size: 1.16rem;
    color: #666;
    transition: background 300ms ease, color 300ms ease;
}

.suggestion-list__item:hover .suggestion-list__link,
.suggestion-list__item---selected .suggestion-list__link {
    font-weight: bold;
    background: #ccc;
    color: var(--font-color);
}

.suggestion-list__image {
    padding-left: 0;
    padding-right: 0;
    max-width: 100px;
    overflow: hidden;
}
