/* Portal - KNT */

.mod__knt {
    display: grid;
    gap: 2rem;
}

.knt__item {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    transition: box-shadow 0.5s ease;
    /*border: thin solid var(--color-primary);*/
    width: 100%;
}

.knt__item:hover {
    box-shadow: var(--box-shadow);
}

.img-top .mod__knt .knt__item {
    flex-direction: column;
}

.col-2 .mod__knt {
    grid-template-columns: repeat( auto-fill, minmax(440px, 1fr));
}

.col-3 .mod__knt {
    grid-template-columns: repeat( auto-fill, minmax(292px, 1fr));
}

.col-4 .mod__knt {
    grid-template-columns: repeat( auto-fill, minmax(260px, 1fr));
}

.reverse .mod__knt .knt__item:nth-of-type(even) {
    flex-direction: row-reverse;
}

.col-1 .mod__knt .knt__item .knt__img,
.col-1 .mod__knt .knt__item .knt__text {
    flex: 0 0 50%;
}

.knt__button {
    padding: 3px 30px;
    margin-top: auto;
}

.knt__img .galbild_vorsch img {
    aspect-ratio: 4/3;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.knt__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px;
    flex-grow: 1;
}

.blog .knt__text {
    position: relative;
    background-color: #fff;
    bottom: 2rem;
    left: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.knt__img {
    /*aspect-ratio: 4 / 3;*/
}

.galbild_vorsch {
    height: 100%;
    aspect-ratio: 4/3;
    max-width: 100%;
}

.gal_list img {
    aspect-ratio: 4 / 3 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gal__slider img {
    aspect-ratio: 16 / 5 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img-1 .knt__img,
.img-1 .galbild_vorsch,
.img-1 img,
.img-1 .gal__slider img {
    aspect-ratio: 1 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img-4-3 .knt__img,
.img-4-3 .galbild_vorsch,
.img-4-3 img,
.img-4-3 .gal__slider img {
    aspect-ratio: 4 / 3 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img-16-5 .knt__img,
.img-16-5 .galbild_vorsch,
.img-16-5 img {
    aspect-ratio: 16 / 5 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img-16-9 .knt__img,
.img-16-9 .galbild_vorsch,
.img-16-9 img,
.img-16-9 .gal__slider img {
    aspect-ratio: 16 / 9 !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card .knt__item {
    display: flex;
    flex-direction: column;
}

.card .knt__text {
    align-items: center;
}

.card .knt__text .knt__titel {
    min-height: 2.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /*word-break: break-all;*/
    word-break: break-word;
    margin-top: 0;
}

.card .knt__text .teasertext {
    min-height: 4.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /*word-break: break-all;*/
    word-break: break-word;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .col-2 .mod__knt,
    .col-3 .mod__knt,
    .col-4 .mod__knt {
        grid-template-columns: repeat( auto-fill, minmax(260px, 1fr));
    }
    .mod__knt .knt__item,
    .reverse .mod__knt .knt__item:nth-of-type(even){
        flex-direction: column;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .col-2 .mod__knt{
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .col-2 .mod__knt{
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}