/* Konto */

.konto_xs_accordion {
    margin-top: 20px;
}

.konto_xs_accordion>div>div>.panel-default {
    border: none;
    box-shadow: none;
}

.konto_xs_accordion>div>div>.panel-default>.panel-heading {
    background-color: #fff;
    border: none;
}

/*.kontosuche>.input-group>div.input-group-addon {
    color: var(--color-primary);
    background-color: transparent;
}*/

.konto_down-up {
    text-align: center;
}

.konto_passwortfeld {
    -webkit-text-security: disc;
}

.kachel__wrapper .bestvorschlag__text {
    min-height: 70px;
}

.bestvorschlag__text.konto__besttext {
    min-height: 0;
}

label {
    font-weight: 400;
    font-size: 0.9rem;
}

.beleg__pdf i {
    color: #AD0B00;
    font-size: 2rem;
}

.hr-clear {
    border-color: transparent !important;
}

/*.felder {
    max-width: 60%;
}*/

.felder .form-group {
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 0 40px;
}

.konto__menu {
    background-color: var(--color-secundary);
}

.konto__menu * {
    color: #fff !important;
}

.felder .form-group label{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.konto__menu li.sidemenu_row a,
.konto__menu li.sidemenu_ug_row a {
    line-height: 44px;
}

.konto__menu li.sidemenu_row.active>a,
.konto__menu li.sidemenu_ug_row.active>a {
    font-weight: 600;
}


.felder .form-group:before,
.felder .form-group:after {
    content: none;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .felder {
        max-width: unset;
    }
}

