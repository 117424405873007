/* Control */
/* RESULT > CONTROL */

.control {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.control__attribute .form-control {
    padding: 9px;
}

.control li {
    margin-left: 5px;
}

.control .svg-icon {
    height: 19px;
    width: 19px;
}

.control__attribute,
.control__q {
    display: flex;
}

.control__q {
    margin-left: 5px;
}

.control__direction {
    margin-left: 5px;
}

.control__block {
    /*margin-left: auto;*/
    display: flex;
}

.control__view,
.control__limit {
    display: flex;
}

.control__view {
    margin-right: 15px;
}

.control__btn {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #fff;
    color: var(--font-color);
    border: thin solid var(--color-primary);
    transition: background 300ms ease, color 300ms ease, border 300ms ease;
}

.control__q .control__btn {
    width: unset;
}

.control__btn:hover {
    color: #fff;
    background: var(--color-primary);
}

.control__btn.xxx---is-active,
.control__btn.aktiv {
    color: #fff !important;
    background: var(--color-primary);
    border-color: var(--color-primary);
}

.control__amount {
    margin-left: 15px;
}

.control__close {
    padding-left: 5px;
}

@media (min-width: 768px) {
    .control__attribute {
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .control li:first-child,
    .control__q {
        margin-left: 0;
    }
    .control__attribute {
        margin-bottom: 10px;
        width: 100%;
    }
    .control__attribute label {
        width: 100%;
    }
    .control__block {
        margin-top: 5px;
        margin-left: 0;
    }
    .control__limit {
        margin-left: 5px !important;
    }
}
