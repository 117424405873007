/* FOOTER */

.xxx--page-footer {
    background-color: var(--color-primary);
    margin-top: -6px;
}

.xxx--page-footer * {
    color: #fff !important;
}

.main__footer {
    display: flex;
    font-size: 1.3rem;
}

.main__footer .nav a {
    line-height: 30px;
    font-size: 1.2rem;
}

.main__footer .nav>li>a:focus,
.main__footer .nav>li>a:hover {
    text-decoration: none;
    background-color: transparent;
}

.footer__svg .svg-icon {
    height: 44px;
    max-width: 50px;
}

svg.svg-icon.svg-icon-gea-fb,
svg.svg-icon.svg-icon-gea-yu {
    fill: none;
    stroke: currentColor;
    stroke-width: 2px;
}

@media (max-width: 1199px) {
	.main__footer {
        display: block;
    }
    .main__footer > div {
        border-bottom: 1px solid #fff;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
}

