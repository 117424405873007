/* Artikeldetail */

.art__bez {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.1rem;
    color: var(--color-secundary);
}

.detail {
    display: flex;
    flex-direction: column;
}

.detail .btn {
    color: var(--color-primary);
}

.detail .btn:hover {
    color: var(--color-secundary);
}


.detail .svg-icon {
    height: 1rem;
    width: 1rem;
}

.detail__top .detail__img img {
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
}

.detail__top .carousel-control {
    display: none;
}

.svg-icon.svg-icon-mz {
    fill: none;
    stroke: currentColor;
    stroke-width: 4px;
}

.detail__blaettern {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.detail__blaettern .svg-icon {
    height: 2rem;
    width: 2rem;
}

.detail__blaettern .icon__left {
    margin-right: auto
}

.detail__blaettern .icon__right {
    margin-left: auto
}

.detail__back .svg-icon {
    height: 12px;
    width: 12px;
}

.detail__top {
    display: flex;
    gap: 2%;
    position: relative;
    /*align-items: flex-start;*/
}


.detail__img {
    flex: 0 0 49%;
    display: flex;
    background-color: var(--color-grau);
}

.detail__img .carousel-inner:hover img {
    transform: scale(1.6);
    transition: transform .6s ease-in-out;
}

.alterimg__container {
    display: flex;
    flex-direction: column;
    gap: 2%;
    flex: 0 0 15%;
}

.hauptimg__container {
    flex: 0 0 82.5%;
    position: relative;
}

.alternativ__img {
    border: 1px solid var(--color-primary);
    padding: 5px;
    border-radius: 5px;
}

.alternativ__img img {
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 5px;
}

/*
.detail .carousel-control {
    display: none;
}
*/

a.detail__btn {
    color: var(--color-secundary);
}

.detail__menge {
    /*max-width: 120px;*/
}

.detail__content {
    flex: 0 0 49%;
    display: flex;
    flex-direction: column;
}

.varianten__merkmale .input-group {
    width: 100%;
}

.buttons {
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}

.detail__bottom {
    display: flex;
    flex-direction: column;
}

.detail__bottom .bottom__container {
    margin-bottom: 2rem;
}

.detail__bottom .detail__preis p,
.detail__bottom .detail__preis span {
    font-size: 1.1rem;
}

.anhang__items {
    text-align: center;
}

.detail__preis p.aktionpreis__info span {
    font-size: 1rem;
    font-weight: 500;
}

.input__block {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
}

.gea__select {
    appearance: none;
}

.gea__select.form-control {
    background-color: transparent;
}

.input__varianten i {
    top: 18px;
    right: 20px;
    font-size: 18px;

}

.preorder {
    background-color: #d8d8d8;
}

/*===================== Zubehoer ==============================*/

.alt__container .art__bez {
    min-height: 90px;
}

.zub__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapper.zub__wrapper {
    display: block;
}

.zub  {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem 2.6%;
}

.zub .wrapper {
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    transition: box-shadow 500ms ease;
    flex-direction: column;
}

.zub .wrapper:hover {
    box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
}

.zub .zub__item {
    flex: 0 0 23%;
    max-width: 23%;
}

.zub__img img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
}

.zub__container .panel-body {
    padding: 0;
}

.zub__preis p,
.zub__preis span {
    color: var(--color-primary);
    font-size: 1.6rem;
    font-weight: 700;
}

.zub__menge {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/*=====================/ Zubehoer ==============================*/


.slide .wrapper {
    display: flex;
    gap: 2%;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    border-radius: 5px;
}

.wrapper__img {
    flex: 0 0 20%;
}

.wrapper__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.slide .wrapper__content {
    align-items: flex-start;
}

.wrapper__buttons {
    flex: 0 0 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px;
}

.slide .wrapper__buttons {
    flex-direction: column;
}

.wrapper__img img {
    aspect-ratio: 1;
    object-fit: contain;
}

/*.sachmerkmale dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.4rem 2%;
    margin: 0;
    line-height: 30px;
}*/

.sachmerkmale dl {
    display: block;
    margin: 0;
    line-height: 30px;
}

.sachmerkmale + .sachmerkmale {
    margin-top: 0.5rem;
}

.sachmerkmale dt,
.sachmerkmale p {
    padding-left: 0.5rem;
}


.sachmerkmale dt,
.sachmerkmale dd {
    line-height: 2;
}

/*.sachmerkmale dd {
    border: 1px solid #d9d9d9;
}*/

/*.sachmerkmale:nth-of-type(odd) dl dt,
.sachmerkmale:nth-of-type(odd) dl dd {
    background-color: #d9d9d9;
}*/

.sachmerkmale:nth-of-type(odd) dl dt {
    background-color: #fff;
}

.sachmerkmale dl dt {
    text-decoration: underline;
}

.sachmerkmale:nth-of-type(odd) dl dd p:nth-of-type(even) {
    background-color: #fff;
}

/*.sachmerkmale:nth-of-type(even) dl dd p:nth-of-type(even) {
    background-color: #d9d9d9;
}*/

.sachmerkmale dl dd p:nth-of-type(odd) {
    background-color: #d9d9d9;
}

/*.artikeldetail__accordion .panel-title a {
    color: var(--color-primary);
    font-size: 1.16rem;
}*/

.artikeldetail__accordion .panel-title h3,
.artikeldetail__accordion .panel-title a,
.artikeldetail__accordion .panel-title span {
    color: var(--color-primary);
    font-size: 1.16rem;
    text-transform: capitalize;
    font-weight: 700;
    margin: 0;
}

@media (max-width: 767px) {
    .detail__top {
        flex-direction: column;
        gap: 0.8rem 0;
    }
    .detail__info {
        flex-direction: column;
        width: 100%;
    }
    .top__info {
        width: 100%;
        flex-direction: column;
        gap: 0.8rem 0;
    }
    .detail .buttons .detail__btn {
        display: flex;
        height: 2.4rem;
        width: auto;
        border-radius: 5px;
    }
    .anhang-tab__items {
        margin-bottom: 15px;
    }

    .anhang__item {
        margin-top: 15px;
    }

    .anhang-tab__items {
        gap: 8px;
    }

    .anhang-tab__item {
        max-height: 60px;
    }
    .buttons {
        flex-direction: column;
    }
    .buttons div {
        width: 100%;
    }
    .buttons div + div {
        margin-top: 0.5rem;
    }
    .slide .wrapper {
        flex-direction: column;
    }
    .wrapper__img {
        aspect-ratio: 4/3;
    }
    .wrapper__img img {
        aspect-ratio: 4/3;
        object-fit: contain;
    }
    .wrapper__content,
    .slide .wrapper__content {
        align-items: center;
    }
    .sachmerkmale dl {
        display: block;
    }
    /*.sachmerkmale:nth-of-type(odd) dl dt {
        background-color: #fff;
    }*/
    /*.sachmerkmale dl dt {
        text-decoration: underline;
    }*/
    .slide .wrapper__buttons {
        flex-direction: row;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .detail__top {
        flex-direction: column;
        gap: 0.8rem 0;
    }
    .detail__info {
        width: 100%;
    }
    .zub  {
        gap: 0.6rem 2%;
    }
    .zub .zub__item {
        flex: 0 0 32%;
        max-width: 32%;
    }
}

.detail__bestand .svg-icon {
    height: 1rem;
    width: 1rem;
}

.detail__bestand .circle_red .span {
    color: var(--bestand-r);
}

.detail__bestand .circle_yellow .span {
    color: var(--bestand-y);
}

.detail__bestand .circle_green .span {
    color: var(--bestand-g);
}

/*
 *
 * Artikeldetail
 */

.blaettern {
    text-align: center;
    white-space: nowrap;
    line-height: 2.5;
}



#artikeldetail_overlay {
    display: none;
    position: absolute;
    top: 0;
    right: -100%;
    z-index: 10;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    background: #fff no-repeat;
    background-size: 200%;
}

.detail .input-group > .input-group-addon {
    height: 34px;
}

.detail__preis p,
.detail__preis span {
    color: var(--color-primary);
    font-size: 1.3rem;
    font-weight: 700;
}

div.preis__bemerkung {
    text-align: right;
}

div.preis__bemerkung p,
div.preis__bemerkung a {
    font-size: 0.8rem;
}


.tabset > .nav-tabs {
    border: none;
}

.tabset > .nav-tabs > li > a {
    color: var(--font-color);
    font-size: 1.16rem;
    border: none;
    line-height: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.tabset > .nav-tabs > li.active > a {
    color: var(--color-primary);
    font-weight: 700;
    border: none;
    text-decoration: underline;
}

.tabset > .nav-tabs > li > a > div > h2 {
    color: var(--font-color);
    font-size: 1.16rem;
    line-height: 2.5rem;
    text-transform: capitalize;
    margin: 0;
}

.tabset > .nav-tabs > li.active > a > div > h2{
    color: var(--color-primary);
    font-weight: 700;
}

.tabset > .nav-tabs > li > a:hover {
    color: var(--color-primary);
    background-color: transparent;
}

.tabset > .tab-content {
    padding: 15px;
    /*border: 1px solid var(--color-primary);*/
    border-radius: 0 5px 5px 0;
}

.tabset__tab {
    /*border: 1px solid var(--color-primary);*/
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}

.mengumrechnung > tbody > tr:hover {
    opacity: .7;
}

/*.sachm:after {
    content: ', ';
}

.sachm:last-child:after {
    content: '';
}*/

.anhang {
    display: flex;
    flex-direction: column;
}

.anhang-tab__item {
    max-height: 80px;
}

.anhang-tab__items {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.anhang-tab__items.tab-items__pictogramme {
    gap: 10px;
}

.tab-items__img .anhang-tab__item {
    height: 80px;
}

.anhang-tab__item a {
    color: var(--color-primary);
    font-weight: 700;
    text-decoration: underline;
}

.anhang-tab__item img {
    max-width: 100%;
    max-height: 100%;
}

.anhang-tab__item i {
    color: var(--color-primary);
    font-size: 1rem;
    font-weight: 700;
    margin-right: 5px;
}

.anhang__item {
    margin-top: 30px;
}

.artikeldetail_aktionspreis_altpreis {
    color: var(--font-color);
    font-size: 1.1rem;
    font-weight: 400;
    /*text-decoration: line-through;*/
}

.artikeldetail_aktionspreis_altpreis p,
.artikeldetail_aktionspreis_altpreis span,
.preis__gross span {
    color: var(--color-primary);
    font-size: 1.1rem;
}

/*.detail .hersteller img,*/
.merkzettel_info .hersteller img {
    height: 64px;
}


