/* SQUARE */

.gruppen {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px 2rem;
    margin-top: 1rem;
}

.gruppen::before,
.gruppen::after {
    content: none;
}

.gruppen__link {
    /*position: relative;*/
    display: block;
    /*overflow: hidden;*/
    border: thin solid var(--color-primary);
    transition: box-shadow 800ms ease;
}

.gruppen__link:hover {
    box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
}

.gruppen__container {
    aspect-ratio: 1;
    height: 100%;
}

.gruppen__image {
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

.gruppen__label,
.gruppen__count {
    padding: 5px 7px;
    z-index: 10;
    color: #fff;
}

.gruppen__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-primary);
    min-height: 50px;
}

.gruppen__info h2 {
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0;
}

[data-object-fit='cover'] {
    object-fit: cover
}

@media all and (-ms-high-contrast:none) {
    /* IE11 */
    *::-ms-backdrop,
    .link__image img.link__image-img {
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

    }
    *::-ms-backdrop,
    .link__image {
        background-color: #e6e6e6;
        border-radius: 4px;
    }
    /* IE11 */
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .gruppen {
        grid-template-columns: 1fr;
    }
}

/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .gruppen {
        grid-template-columns: 1fr 1fr;
    }
}
