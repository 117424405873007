@charset "utf-8";

:root {
   --font-primary: 'Arial', sans-serif;
   --font-secundary: 'GEASans', sans-serif;
   --color-primary: #000F41;
   --color-secundary: #0303B8;
   --color-grau: #F5F5F5;
   --color-button: #1AFF80;
   --font-color: #000;
   --link-color: #0303B8;
   --grid-color: lightgray;
   --bestand-r: #f00;
   --bestand-y: #ffcc00;
   --bestand-g: #00B050;
   --box-shadow: 0 5px 15px var(--color-grau);
   --nav-down: plus;
   --nav-up: minus;
   --scrollbar-track-color: #ededed;
   --scrollbar-thumbs-color: #7BBEA8;
}

@media (min-width: 1600px) {
   .container {
      width: 1330px;
   }
}



