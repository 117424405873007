/* Cookie */


*[ng-if="showCookieWarn"] {
    /* sticky-top */
    /* padding-top: 220px; */
    /* sticky-bottom */
    padding-bottom: 220px;
}

@media (min-width: 420px) {
    *[ng-if="showCookieWarn"] {
        /* sticky-top */
        /* padding-top: 140px; */
        /* sticky-bottom */
        padding-bottom: 140px;
    }
}

@media (min-width: 720px) {
    *[ng-if="showCookieWarn"] {
        /* sticky-top */
        /* padding-top: 60px; */
        /* sticky-bottom */
        padding-bottom: 60px;
    }
}

.cookie-warn--message {
    font-weight: lighter;
    display: block;
    padding: 10px 40px 10px 0;
}

.cookie-warn {
    width: 100%;
    background: #FFF;
    color: #707070;
}
*[class*="cookie-warn---sticky-"] {
    position: fixed;
    z-index: 1100;
    left: 0;
    right: 0;
}
.cookie-warn---sticky-top {
    top: 0;
}
.cookie-warn---sticky-bottom {
    bottom: 0;
}
.cookie-warn---shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, .3);
}
.cookie-warn .container {
    position: relative;
}

.cookie-warn--close {
    position: absolute;
    right: 5px;
}
.cookie-warn---sticky-top .cookie-warn--close {
    bottom: 10px;
}
.cookie-warn---sticky-bottom .cookie-warn--close {
    top: 10px;
}
.cookie-warn--mobile a,
.cookie-warn--message a {
    color: inherit !important;
    font-size: inherit !important;
    text-decoration: underline !important;
    transition: color 300ms ease;
}
.cookie-warn--mobile a:hover,
.cookie-warn--message a:hover {
    color: #000 !important;
}


.xxx---cookiebot {
    display: flex;
    cursor: pointer;
}

.xxx---cookiebot-videodummy {
    width: 100%;
    height: 40vw;
    max-height: 500px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 50% 50%;
    border: 1px solid #545454;
    filter: blur(5px);
    position: relative;
    opacity: 0.5;
    -ms-filter: alpha(opacity=25);
}

.xxx---cookiebot-playbutton {
    position: absolute;
    bottom: 45%;
    left: 45%;
}

.xxx---cookiebot-playbutton .fa {
    font-size: 6vw;
    margin: auto;
    z-index: 500;
}

.xxx---cookiebot-text {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 20px;
}