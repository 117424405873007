/* Navigation */

.navbar li a:link,
.navbar li a:visited,
.navbar li a:hover,
.navbar li a:active,
.navbar li a:focus {
    font-size: 1.16rem;
    text-decoration: none;
}

.navbar-default {
    background-color: #fff;
    border: none;
    margin-bottom: 0;
    min-height: 1px;
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>div>a,
.navbar-default .navbar-nav>li>div>div>a{
    font-size: 1.3rem;
    color: var(--color-secundary);
    font-weight: 500;
    text-transform: capitalize;
}

.navbar-default .navbar-nav>li>a:hover {
    color: var(--color-primary);
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: var(--color-primary);
    background-color: transparent;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: var(--font-color);
    background-color: #fff;
}


.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: var(--font-color);
    text-decoration: none;
    background-color: #fff;
}

.navbar-default .navbar-collapse {
    border: none;
}

.navbar-default .navbar-toggle {
    margin: 0;
    border: none;
}

.navbar-default .navbar-toggle .icon-bar {
    width: 35px;
    height: 3px;
    margin-bottom: 5px;
    background-color: var(--color-secundary);
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background: none;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
    color: var(--color-primary);
    background: #fff;
}

.navbar-icon>a>i {
    padding: 10px;
    color: var(--color-primary);
    font-size: 25pt;
}

.navbar-logo {
    display: none !important;
}

.navbar-logo img {
    max-height: 100%;
    max-width: 100%;
}

.nav>li>div>a,
.nav>li>div,
.nav>li>a {
    padding: 0;
    line-height: 20px;
}

.nav-stacked .sidemenu_head {
    min-height: 30px;
    padding: 5px;
}

.nav-stacked .sidemenu_row {
    min-height: 30px;
    border-top: 1px solid var(--font-color);
}

.nav-stacked>li.active>a,
.nav-stacked>li.active:hover>a {
    color: var(--color-primary);
    background: #fff;
}

.nav-stacked .sidemenu_row>a>span {
    margin-top: 6px;
}

.nav-stacked .sidemenu_row>a {
    padding: 12px 5px 9px 5px;
}

.nav-stacked .sidemenu_row>a:hover {
    color: var(--color-primary);
    background-color: #fff;
}

.nav>li>a:hover {
    background-color: transparent;
}

.navbar-nav {
    display: flex;
    margin: 0;
    gap: 2.4rem;
    flex-wrap: wrap;
}

.navbar-nav.header__nav {
    gap: 1rem;
}

.navbar-collapse {
    padding: 10px 15px;
}

.navbar-collapse.in {
    overflow: hidden;
}

@media (min-width: 768px) {
    .navbar-nav {
        float: unset;
    }
}
@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu .dropdown-header,
    .navbar-nav .open .dropdown-menu>li>a {
        padding: 5px 15px 5px 0;
    }

    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-collapse .navbar-nav {
        flex-direction: column;
        gap: unset;
    }

    .navbar-header {
        display: flex;
        flex-direction: column;
    }

    .navbar__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
    }

    .navbar__bottom {
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar__bottom ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar__bottom span {
        display: none;
    }

    .navbar-header-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon .navbar-icon i {
        font-size: 20pt;
    }

    .navbar-header-icon:before,
    .navbar-header-icon:after {
        content: none;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        position: absolute;
        top: 100%;
        z-index: 10000;
        width: 100%;
        background-color: #fff;
        box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    }
    .dropdown-menu {
        padding: 0;
    }
}

/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .navbar__bottom span {
        display: none;
    }
    .navbar-collapse .navbar-nav {
        flex-direction: column;
        gap: unset;
    }
    .navbar__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
    }
    .navbar__bottom {
        padding-left: 15px;
        padding-right: 15px;
    }
}
