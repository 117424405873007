/* Position */

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.fixed {
    position: fixed !important;
}

.static {
    position: static !important;
}

.transparent {
    background-color: transparent !important;
}

/* Font */

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.ta-start {
    text-align: start !important;
}

.ta-end {
    text-align: end !important;
}

.ta-center {
    text-align: center !important;
}

.h-100 {
    height: 100% !important;
}

w-25 {
    width: 25% !important;
}
.w-33 {
    width: 33% !important;
}
.w-50 {
    width: 50% !important;
}
.w-66 {
    width: 66% !important;
}
.w-75 {
    width: 75% !important;
}
.w-100 {
    width: 100% !important;
}
/* Display */

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-none {
    display: none !important;
}

.d-grid {
    display: grid !important;
}

.d-inline-grid {
    display: inline-grid !important;
}

/* Zusatz - Flex */

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.gap-0 {
    gap: 0 !important;
}

.gap-1 {
    gap: 5px !important;
}

.gap-2 {
    gap: 10px !important;
}

.gap-3 {
    gap: 15px !important;
}

.gap-4 {
    gap: 30px !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-content-around {
    align-content: space-around !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.justify-around {
    justify-content: space-around !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-evenly {
    justify-content: space-evenly !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

/* Zusatz - Margin */
.m-auto {
    margin: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 5px !important;
}

.m-2 {
    margin: 10px !important;
}

.m-3 {
    margin: 15px !important;
}

.m-4 {
    margin: 30px !important;
}

.m-5 {
    margin: 45px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 5px !important;
}

.mt-2 {
    margin-top: 10px !important;
}

.mt-3 {
    margin-top: 15px !important;
}

.mt-4 {
    margin-top: 30px !important;
}

.mt-5 {
    margin-top: 45px !important;
}

.mt-6 {
    margin-top: 60px !important;
}


.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 5px !important;
}

.mb-2 {
    margin-bottom: 10px !important;
}

.mb-3 {
    margin-bottom: 15px !important;
}

.mb-4 {
    margin-bottom: 30px !important;
}

.mb-5 {
    margin-bottom: 45px !important;
}

.mb-6 {
    margin-bottom: 60px !important;
}

.mb-7 {
    margin-bottom: 80px !important;
}

.mtb-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.mtb-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.mtb-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mtb-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.mtb-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mtb-5 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 5px !important;
}

.ml-2 {
    margin-left: 10px !important;
}

.ml-3 {
    margin-left: 15px !important;
}

.ml-4 {
    margin-left: 30px !important;
}

.ml-5 {
    margin-left: 45px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 5px !important;
}

.mr-2 {
    margin-right: 10px !important;
}

.mr-3 {
    margin-right: 15px !important;
}

.mr-4 {
    margin-right: 30px !important;
}

.mr-5 {
    margin-right: 45px !important;
}

.mlr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mlr-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mlr-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mlr-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mlr-4 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.mlr-5 {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

/* Zusatz - Padding */

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 5px !important;
}

.p-2 {
    padding: 10px !important;
}

.p-3 {
    padding: 15px !important;
}

.p-4 {
    padding: 30px !important;
}

.p-5 {
    padding: 45px !important;
}

.p-6 {
    padding: 60px !important;
}

.pt-zero,
.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 5px !important;
}

.pt-2 {
    padding-top: 10px !important;
}

.pt-3 {
    padding-top: 15px !important;
}

.pt-4 {
    padding-top: 30px !important;
}

.pt-5 {
    padding-top: 45px !important;
}

.pt-6 {
    padding-top: 60px !important;
}


.pb-zero,
.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 5px !important;
}

.pb-2 {
    padding-bottom: 10px !important;
}

.pb-3 {
    padding-bottom: 15px !important;
}

.pb-4 {
    padding-bottom: 30px !important;
}

.pb-5 {
    padding-bottom: 45px !important;
}

.ptb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ptb-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.ptb-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ptb-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.ptb-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.ptb-5 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 5px !important;
}

.pl-2 {
    padding-left: 10px !important;
}

.pl-3 {
    padding-left: 15px !important;
}

.pl-4 {
    padding-left: 30px !important;
}

.pl-5 {
    padding-left: 45px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 5px !important;
}

.pr-2 {
    padding-right: 10px !important;
}

.pr-3 {
    padding-right: 15px !important;
}

.pr-4 {
    padding-right: 30px !important;
}

.pr-5 {
    padding-right: 45px !important;
}

.plr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.plr-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.plr-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.plr-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.plr-4 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.plr-5 {
    padding-left: 45px !important;
    padding-right: 45px !important;
}


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .flex-xs-column {
        flex-direction: column !important;
    }

    .flex-xs-row {
        flex-direction: row !important;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row {
        flex-direction: row !important;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row {
        flex-direction: row !important;
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {
    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row {
        flex-direction: row !important;
    }
}