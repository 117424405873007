/* Swiper */

swiper-container {
    width: 100%;
    height: 100%;
}

swiper-slide {
    text-align: center;
    font-size: 1rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

swiper-slide img {
    aspect-ratio: 16/5;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

swiper-container::part(bullet) {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
}

swiper-container::part(bullet-active) {
    width: 12px;
    height: 12px;
    background-color: var(--color-primary);
    border-radius: 50%;
    border: 1px solid #fff;
}

swiper-container::part(button-prev),
swiper-container::part(button-next) {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 7px 14px;
    transition: all .6s ease;
}

swiper-container::part(button-prev):hover,
swiper-container::part(button-next):hover {
    color: var(--color-secundary);
    background-color: rgba(0, 0, 0, 0.4);
}

swiper-container::part(button-prev) .swipper-button-prev,
swiper-container::part(button-next) .swipper-button-next {
    height: 60%;
}

swiper-container::part(button-prev) .swipper-button-prev {
    left: 0 !important;
}

swiper-container::part(button-next) .swipper-button-next {
    right: 0 !important;
}

