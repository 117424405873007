/* KNT */

/*.knt__img {
    display: flex;
    flex-direction: column;
    !*overflow: hidden;*!
}*/

.grid .knt__img {
    display: block;
}